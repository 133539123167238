import UserRole from './UserRole'
import ID from './ID'
import Tactic from './Tactic'
import Channel from './Channel'
import Tag from './Tag'
import User from './User'
import Budget from './Budget'
import PlanDocumentCategory from './PlanDocumentCategory'
import PlanDocument from './PlanDocument'
import TacticField from './TacticField'

export enum PlanRatingPointsType {
  'TRP' = 'trp',
  'GRP' = 'grp',
}

export enum PlanType {
  'Individual' = 'Individual',
  'Master' = 'Master',
  'Sub' = 'Sub',
}

export enum PlanWeekStartDay {
  'Sunday' = 'sunday',
  'Monday' = 'monday',
}

export default class Plan {
  constructor(
    public id: ID = new ID(),
    public creator: User = new User(),
    public name: string = '',
    public type: PlanType = PlanType.Individual,
    public startDate: Date = new Date(),
    public endDate: Date = new Date(),
    public weekStartDay: PlanWeekStartDay = PlanWeekStartDay.Sunday,

    public channels: Channel[] = [],
    public userRoleIds: ID[] = [],
    public tactics: Tactic[] = [],
    public budgetIds: ID[] = [],
    public tags: Tag[] = [],
    public subscriptionIds: ID[] = [],
    public ratingPointsType: PlanRatingPointsType = PlanRatingPointsType.GRP,

    public plans: ID[] = [], //IDs for sub-plans if PlanType is Master
    public parentId: ID = new ID(), //ID for parent Master plan if PlanType is Sub
    public accountId: ID = new ID(),

    public isTemplate: boolean = false,
    public useBudgets: boolean = false,

    public defaultChannels: Channel[] = [],

    public planDocumentCategories: PlanDocumentCategory[] = [],
    public documents: PlanDocument[] = [],
    public tacticFields: TacticField[] = [],

    public isFrozen: boolean = false,

    public initiativeChannel: Channel = new Channel(),
    private isInitiativeChannelConsolidated: boolean = false, // Populated on FE
    public userRoles: UserRole[] = [], // Populated on FE
    public budgets: Budget[] = [], // Populated on FE
  ) {}

  public static SUPER_PLAN_ID = process.env.VUE_APP_SUPER_PLAN_ID //STANGA DEV: 27 / ANNUM STAGE: 15 / ANNUM PROD: 15

  public get key() {
    return 'plan-' + this.id.intID.toString()
  }

  public get account(): ID {
    return this.accountId
  }

  public get isBudgetEnabled(): boolean {
    return this.useBudgets
  }

  public get isTacticSummaryFieldEnabled(): boolean {
    // Return true if at least one channel has the summary field enabled - could switch to managing this feature at the channel level in the future - JV 11.28.22
    return this.channels.filter((channel)=>channel.isSummaryEnabled).length > 0
  }

  public get abbreviatedName(): string {
    const splitName = this.name.split(' ')
    if (splitName.length > 1) {
      return String(splitName[0][0] + splitName[1][0]).toUpperCase()
    } else {
      return this.name.substr(0, 2).toUpperCase()
    }
  }

  public get userIds(): number[] {
    return this.userRoles.map((userRole) => {
      return userRole.userId.intID
    })
  }

  public get totalBudget(): number {
    let returnValue = 0
    this.budgets.forEach((budget) => {
      returnValue += budget.value
    })
    return returnValue
  }

  public get consolidatedInitiativeChannel(): Channel {
    if(!this.isInitiativeChannelConsolidated){
      // Get all tactic type names from initiatives channel for later comparison
      const initiativeTypeNames = this.initiativeChannel.tacticTypes.map((type) => type.name.toLowerCase())
      // Add tactic references to the correct type within the main initiatives channel
      this.initiativeChannel.tacticTypes.forEach((mainType) => {
        // Find IDs of all matching initiative types
        const matchingTypeIds = [] as number[]
        this.channels.forEach((channel)=>{
          if(channel.name === Channel.CHANNEL_NAME_INITIATIVES){
            channel.tacticTypes.forEach((type)=>{
              if(type.name.toLowerCase() === mainType.name.toLowerCase()){
                matchingTypeIds.push(type.id.intID)
              }else if(!initiativeTypeNames.includes(type.name.toLowerCase())){
                this.initiativeChannel.tacticTypes.push(type)
                initiativeTypeNames.push(type.name.toLowerCase())
              }
            })
          }
        })
        // Populate tactics from matching types
        mainType.tactics = this.tactics.filter(
          (tactic) => matchingTypeIds.includes(tactic.tacticTypeId.intID)
        )
      })
      this.initiativeChannel.tacticTypes.sort((a,b) => a.orderIndex - b.orderIndex)
      this.isInitiativeChannelConsolidated = true
    }
    return this.initiativeChannel
  }

  public static fromResponseObject(obj): Plan {
    if (!obj) return new Plan()

    const channels = [] as Channel[]
    let initiativeChannel = new Channel()
    if (obj.channels) {
      obj.channels.forEach((element) => {
        if (element.name === Channel.CHANNEL_NAME_INITIATIVES) {
          if(initiativeChannel.id.intID == 0){
            initiativeChannel = Channel.fromResponseObject(element)
          }
        }
        channels.push(Channel.fromResponseObject(element))
      })
    }

    const defaultChannels = [] as Channel[]
    if (obj.defaultChannels) {
      obj.defaultChannels.forEach((element) => {
        defaultChannels.push(Channel.fromResponseObject(element))
      })
    }

    const userRoleIds = [] as ID[]
    if (obj.userRoles) {
      obj.userRoles.forEach((element) => {
        userRoleIds.push(ID.fromResponseObject(element, 'user_roles'))
      })
    }

    const tactics = [] as Tactic[]
    if (obj.tactics) {
      obj.tactics.forEach((element) => {
        tactics.push(Tactic.fromResponseObject(element))
      })
    }

    const tags = [] as Tag[]
    if (obj.tags) {
      obj.tags.forEach((element) => {
        tags.push(Tag.fromResponseObject(element))
      })
    }

    const subscriptionIds = [] as ID[]
    if (obj.subscriptions) {
      obj.subscriptions.forEach((element) => {
        subscriptionIds.push(ID.fromResponseObject(element, 'subscriptions'))
      })
    }

    const plans = [] as ID[]
    if (obj.plans) {
      obj.plans.forEach((element) => {
        plans.push(ID.fromResponseObject(element, 'plans'))
      })
    }

    const budgetIds = [] as ID[]
    obj?.budgets?.forEach((element) => {
      budgetIds.push(ID.fromResponseObject(element, 'budgets'))
    })

    return new Plan(
      ID.fromResponseObject(obj.id, 'plans'),
      User.fromResponseObject(obj.creator),
      obj.name,
      obj.type,
      new Date(obj.startDate),
      new Date(obj.endDate),
      obj.weekStartDay,
      channels,
      userRoleIds,
      tactics,
      budgetIds,
      tags,
      subscriptionIds,
      obj.ratingPointsType,
      plans,
      obj.parent ? ID.fromResponseObject(obj.parent, 'plans') : new ID(),
      obj.account ? ID.fromResponseObject(obj.account, 'accounts') : new ID(),
      obj.isTemplate,
      obj.useBudgets,
      defaultChannels,
      obj.planDocumentCategories?.map((category) =>
        PlanDocumentCategory.fromResponseObject(category)
      ),
      obj.documents?.map((document) =>
        PlanDocument.fromResponseObject(document)
      ),
      obj.tacticFields?.map((tacticField) =>
        TacticField.fromResponseObject(tacticField)
      ),
      obj.freeze == true ? true : false,
      initiativeChannel,
    )
  }

  public forRequestObject() {
    const returnObj = {
      id: this.id.intID == 0 ? null : this.id.apiID,
      account: this.account.apiID,
      creator: this.creator.id.intID == 0 ? null : this.creator.id.apiID,
      name: this.name,
      type: this.type,
      startDate: this.startDate,
      endDate: this.endDate,
      weekStartDay: this.weekStartDay,
      channels: this.channels
        .filter(
          (channel) =>
            (this.plans.length && channel.isLead) ||
            (!this.plans.length && !channel.isLead)
        )
        .map((channel) => channel.id.apiID),
      defaultChannels: this.defaultChannels.map((channel) => {
        return {
          channel: channel.id.apiID,
          tacticTypes: channel.tacticTypes.map((type) => type.id.apiID),
        }
      }),
      userRoles: this.userRoles.map((user) => user.id.apiID),
      tactics: this.tactics
        .filter(
          (tactic) =>
            (this.plans.length && tactic.isLead) ||
            (!this.plans.length && !tactic.isLead)
        )
        .map((tactic) => tactic.id.apiID),
      tags: this.tags
        .filter(
          (tag) =>
            (this.plans.length && tag.isLead) ||
            (!this.plans.length && !tag.isLead)
        )
        .map((tag) => tag.id.apiID),
      subscriptions: this.subscriptionIds.map((subscriptionId) => {
        return subscriptionId.apiID
      }),
      ratingPointsType: this.ratingPointsType,
      plans: this.plans.map((planId) => planId.apiID),
      budgets: this.budgetIds.map((budgetId)=>budgetId.apiID),
      parent: this.parentId.intID == 0 ? null : this.parentId.apiID,
      isTemplate: this.isTemplate,
      useBudgets: this.useBudgets,
      planDocumentCategories: this.planDocumentCategories
        .map((category) => category.id.apiID)
        .filter((el) => !!el),
      documents: this.documents.map((document) => document.id.apiID),
      tacticFields: this.tacticFields.map((field) => {
        return field.id.apiID
      }),
    } as any

    if (this.accountId.intID !== 0) {
      returnObj.account = this.accountId.apiID
    }

    return returnObj
  }
}
