<template>
  <div class="view-header" :class="{'full-viewport': isFullViewport}">
    <div class="top-container p-d-flex p-jc-between">
      <div class="title-container">
        <h1>
          <span v-if="titleLabel" class="title-label">{{ titleLabel }}</span
          >{{ title }}
        </h1>
      </div>
      <div class="view-actions-container">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="section-navigation-container" v-if="!isFullViewport">
      <div class="plans-nav p-d-flex p-jc-between" v-if="isInPlansSection">
        <div class="p-d-flex">
          <router-link
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/calendar/day`"
            ><Button class="p-button-text" label="Day" icon="pi pi-clock"
          /></router-link>
          <router-link
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/calendar/month`"
            ><Button class="p-button-text" label="Month" icon="pi pi-calendar"
          /></router-link>
          <router-link
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/calendar/flow`"
            ><Button class="p-button-text" label="Flow" icon="pi pi-chart-bar"
          /></router-link>
          <router-link
            v-if="$store.getters.currentPlan.isBudgetEnabled && $store.getters.currentUserRole.canViewBudget"
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/budget`"
            ><Button class="p-button-text" label="Budget" icon="pi pi-dollar"
          /></router-link>
          <router-link
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/table`"
            ><Button class="p-button-text" label="Table" icon="pi pi-table"
          /></router-link>
          <router-link
            :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/search`"
            ><Button class="p-button-text" label="Search" icon="pi pi-search"
          /></router-link>
          <Button
            v-if="canUserAccessPlanSettingsView"
            class="p-button-text"
            label="Plan Settings"
            icon="pi pi-cog"
            @click="
              (event) => {
                this.$refs.planSettingsMenu.toggle(event)
              }
            "
          />
          <OverlayPanel
            class="plan-settings-menu"
            ref="planSettingsMenu"
            appendTo="body"
          >
            <div class="p-d-flex p-flex-column">
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/channels/`"
                :disabled="!canUserAccessChannelsPlanSettings"
                >
                <Button 
                  class="p-button-text" 
                  label="Channels" 
                  />
              </router-link>
              <router-link 
                v-if="canUserAccessCalendarPlanSettings"
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/calendars-of-interest/`"
                >
                <Button 
                  class="p-button-text" 
                  label="Calendars of Interest" 
                  />
              </router-link>
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/strategic-priorities/`" 
                :disabled="!canUserAccessTagsPlanSettings"
                >
                <Button 
                  class="p-button-text" 
                  label="Strategic Priorities" 
                  />
              </router-link>
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/initiatives/`"
                >
                <Button 
                  class="p-button-text" 
                  label="Initiatives" 
                  />
              </router-link>
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/budget/`"
                v-if="$store.getters.currentUserRole.canViewBudget"
                :disabled="!canUserAccessBudgetPlanSettings"
                >
                <Button 
                  class="p-button-text" 
                  label="Budget" 
                  />
              </router-link>
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/documents/`"
                >
                <Button 
                  class="p-button-text" 
                  label="Documents" 
                  />
              </router-link>
              <router-link 
                :to="`/account/${$store.getters.currentAccount.id.intID}/plan/${$store.getters.currentPlan.id.intID}/settings/team/`"
                >
                <Button 
                  class="p-button-text" 
                  label="Team" 
                  />
              </router-link>
            </div>
          </OverlayPanel>
          <Button
            class="p-button-text"
            :label="roleGuideText"
            icon="pi pi-info-circle"
            @click="clickRoleGuide"
          />
        </div>
      </div>
    </div>
    <div class="view-tools-container">
      <slot></slot>
    </div>

    <Dialog
      class="plan-settings-tutorial"
      :modal="true"
      :visible="
        !shouldShowLoadingState &&
        !shouldShowOnboardingTutorial &&
        (
          viewTutorialOverride || 
            (!isAccountOwner &&
            !hideRoleTutorial &&
            viewTutorial &&
            isInPlansSection &&
            !isRoleViewed)
        )
      "
      :showHeader="false"
    >
      <TutorialRoles @updateViewTutorial="updateViewTutorial" :isViewTutorialOverride="viewTutorialOverride" />
    </Dialog>
    <Dialog
      class="plan-settings-tutorial"
      :modal="true"
      :visible="shouldShowOnboardingTutorial"
      :showHeader="false"
    >
      <TutorialOnboarding @closeModal="()=>{shouldShowOnboardingTutorial = false}" />
    </Dialog>
    <div v-if="shouldShowLoadingState" class="loading-state-container">
      <div class="global-init-spinner-container p-d-flex p-ai-center">
        <ProgressSpinner strokeWidth="3" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Menu from 'primevue/menu'
import OverlayPanel from 'primevue/overlaypanel'
import Role from '@/models/Role'
import TutorialRoles from '@/views/TutorialRoles.vue'
import TutorialOnboarding from '@/views/TutorialOnboarding.vue'
import Plan from '@/models/Plan'
import Channel from '@/models/Channel'

Vue.component('Button', Button)
Vue.component('Dialog', Dialog)
Vue.component('Menu', Menu)
Vue.component('OverlayPanel', OverlayPanel)

export default Vue.extend({
  name: 'ViewHeader',
  components: {
    TutorialRoles,
    TutorialOnboarding,
  },
  props: {
    title: String,
    titleLabel: String,
    hideSectionHomeButton: Boolean,
    isFullViewport: Boolean,
    hideRoleTutorial: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      viewTutorial: true as boolean,
      viewTutorialOverride: false as boolean,
      shouldShowLoadingState: true as boolean,
      shouldShowOnboardingTutorial: false as boolean,
    }
  },
  watch: {
    currentPlan(){
      // Check if currentPlan has loaded and data is ready to display - especially relevant when refreshing page while in plans section
      if(this.$route.params.planId && this.currentPlan.id?.intID !== 0){
        this.shouldShowLoadingState = false
      }
    },
  },
  computed: {
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    isInPlansSection(): boolean {
      return (
        this.$route.fullPath.includes('plan') &&
        !this.$route.fullPath.includes('plans') &&
        !this.$route.fullPath.includes('initiative')
      )
    },
    canUserAccessPlanSettingsView(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
    canUserAccessCalendarPlanSettings(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserAccessChannelsPlanSettings(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserAccessTagsPlanSettings(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    canUserAccessBudgetPlanSettings(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(
        this.$store.getters.currentPlan.id
      ) <= Role.LEVEL_PLAN_ADMIN
        ? true
        : false
    },
    roleGuideText(): string {
      if (
        this.$store.getters.currentAccountPermissionLevel ===
        Role.LEVEL_ACCOUNT_OWNER
      ) {
        return 'Role Tutorial: Account Owner'
      }
      switch (
        this.$store.getters.getPermissionLevelForPlanId(
          this.$store.getters.currentPlan.id
        )
      ) {
        case Role.LEVEL_ACCOUNT_ADMIN:
        case Role.LEVEL_MASTER_PLAN_ADMIN:
        case Role.LEVEL_PLAN_ADMIN:
          return 'Role Tutorial: Plan Admin'
        case Role.LEVEL_CONTRIBUTOR:
          return 'Role Tutorial: Contributor'
        case Role.LEVEL_VIEWER:
          return 'Role Tutorial: Viewer'
      }
      return ''
    },
    roleGuideUrl(): string {
      if (
        this.$store.getters.currentAccountPermissionLevel ===
        Role.LEVEL_ACCOUNT_OWNER
      ) {
        return this.$store.getters.tutorialUrlsDict['accountOwner']
      }
      switch (
        this.$store.getters.getPermissionLevelForPlanId(
          this.$store.getters.currentPlan.id
        )
      ) {
        case Role.LEVEL_ACCOUNT_ADMIN:
        case Role.LEVEL_MASTER_PLAN_ADMIN:
        case Role.LEVEL_PLAN_ADMIN:
          return this.$store.getters.tutorialUrlsDict['planAdmin']
        case Role.LEVEL_CONTRIBUTOR:
          return this.$store.getters.tutorialUrlsDict['contributor']
        case Role.LEVEL_VIEWER:
          return this.$store.getters.tutorialUrlsDict['viewer']
      }
      return ''
    },
    isRoleViewed(): boolean {
      if (this.$store.getters.currentUser.rolesObserved.filter((item) => item.plan == this.$store.getters.currentPlan.id.apiID).length > 0) {
        return true
      }
      return false
    },
    isAccountOwner(): boolean {
      return this.$store.getters.currentAccountPermissionLevel === Role.LEVEL_ACCOUNT_OWNER
    },
  },
  mounted(){
    // If not loading a plan directly, hide loading spinner
    if(!this.$route.params.planId || (this.$route.params.planId && this.currentPlan.id?.intID !== 0)){
      this.shouldShowLoadingState = false
    }

    // Show onboarding tutorial modal if coming from onboarding flow
    if(this.$route.query.route && this.$route.query.route == 'onboarding'){
      this.shouldShowOnboardingTutorial = true
    }
  },
  methods: {
    refreshView() {
      this.$emit('ChannelChanged')
    },
    clickRoleGuide() {
      if(this.roleGuideUrl !== ''){
        window.open(this.roleGuideUrl, '_blank')
      }
    },
    updateViewTutorial() {
      this.viewTutorial = false
      this.viewTutorialOverride = false
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.global-nav-collapsed {
  .view-header {
    left: $navCollapseButtonWidth;
    width: calc(
      100% - 3.2rem
    ); //TODO: should use $navCollapseButtonWidth but having issues with calc()
    transition: left 0.3s, width 0.3s;
  }
}
.is-initiative-detail {
  .view-header {
    background-color: #cad3d9;
  }
}
.view-header {
  width: 85%;
  padding: 1.6rem 2.4rem 1rem 1.6rem;
  margin-bottom: 1rem;
  position: relative;
  left: $globalNavWidth;
  transition: left 0.3s, width 0.3s;
  background: #f9f9f9;
  z-index: 3;

  &.full-viewport {
    width: 100%;
    left: 0;
    z-index: 3;

    h1 {
      margin-left: 0.8rem;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    min-height: 3.2rem;
  }
  h1 {
    margin: 0 1rem;
    font-size: 1.6rem;
    line-height: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .title-label {
      display: block;
      color: $dim-gray;
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }
  .view-actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 1;
  }
  .section-navigation-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    a {
      &:not(.only-exact).router-link-active,
      &.only-exact.router-link-exact-active {
        border-bottom: 0.2rem solid $black;
      }
    }
  }
  .view-tools-container {
    .p-field {
      margin-bottom: 0;
    }
  }
  .plans-nav {
    .pi-chart-bar {
      transform: rotate(90deg);
    }
  }
  .loading-state-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.25);
    display: flex;
    justify-content: center;
    align-items: center;

    .global-init-spinner-container {
      background-color: transparent;
    }
  }
}
.plan-settings-menu {
  .p-button-label {
    text-align: left;
  }
}
.plan-settings-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: #fff;

  .plan-settings-modal-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem 1rem 1.6rem;
    background-color: #f9f9f9;

    h2 {
      margin-bottom: 0;
      font-size: 20px !important;
    }
    .p-button-outlined {
      z-index: 1; //Fixing issue with other buttons overlapping outlined buttons on initial render
    }
  }
  .plan-settings-modal-content {
    max-width: 100rem;
    margin: 0 auto;
    padding: 1.6rem;
  }
}
</style>